import { render, staticRenderFns } from "./CouponDetail.vue?vue&type=template&id=3df45436&scoped=true"
import script from "./CouponDetail.vue?vue&type=script&lang=js"
export * from "./CouponDetail.vue?vue&type=script&lang=js"
import style0 from "./CouponDetail.vue?vue&type=style&index=0&id=3df45436&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3df45436",
  null
  
)

export default component.exports